import React, { useEffect, useState } from 'react';
import camelcaseKeys from 'camelcase-keys';
import { graphql, Link } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import * as PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';

import client from '$landing-page/client';
import Button from '$landing-page/components/button';
import CTAButtons from '$landing-page/components/cta-buttons';
import DescriptionBox from '$landing-page/components/description-box';
import FeatureItem from '$landing-page/components/feature-item';
import PublicationsAccordionList from '$landing-page/components/publications-accordion-list';
import { PUBLICATION_STATUS } from '$landing-page/constants';
import BigContentHeaderLayout from '$landing-page/layouts/big-content-header-layout';

import useStyles from './styles';

export const pageQuery = graphql`
    query therapeuticAreaPage($path: String!) {
        site {
            siteMetadata {
                menu {
                    title
                    subMenu {
                        title
                        url
                        id
                    }
                }
            }
        }
        markdownRemark(frontmatter: { path: { eq: $path } }) {
            html
            frontmatter {
                path
                title
                description
                subDescription1
                subDescription2
                items {
                    title
                    description
                    image {
                        childImageSharp {
                            gatsbyImageData(layout: CONSTRAINED, width: 900, quality: 100, placeholder: NONE)
                        }
                    }
                }
            }
        }
    }
`;

const TITLE_ELEMENT_IDS = {
    features: 'features_title',
    publications: 'publications_title',
};

const TherapeuticAreaPage = (props) => {
    const { location, data } = props;
    const therapeuticAreas = data.site.siteMetadata.menu.find((menuItem) => menuItem.title === 'Therapeutic Areas').subMenu;

    const [publications, setPublications] = useState({ initialLoading: true, items: [], isError: false });
    const classes = useStyles();

    const entry = data.markdownRemark;
    const firstItem = entry.frontmatter.items[0];
    const { subDescription1 } = entry.frontmatter;
    const { subDescription2 } = entry.frontmatter;

    useEffect(() => {
        let isUnmounted = false;
        const therapeuticArea = therapeuticAreas.find((area) => area.url.includes(location.pathname));
        const endpoint = `/sales/publication/?status_id=${PUBLICATION_STATUS.published}${
            therapeuticArea?.id ? `&research_field_ids=${therapeuticArea.id}` : ''
        }`;

        client.api
            .get(endpoint)
            .then((response) => {
                if (!isUnmounted) {
                    const publicationsData = camelcaseKeys(response.data.publications, { deep: true });
                    setPublications({ initialLoading: false, items: publicationsData.slice(0, 5) });
                }
            })
            .catch(() => {
                setPublications({ initialLoading: false, items: [], isError: true });
            });

        return () => {
            isUnmounted = true;
        };
    }, [location.pathname, therapeuticAreas]);

    const scrollToElement = (elementId) => {
        const element = document.getElementById(elementId);
        if (element) {
            window.scrollTo({ top: element.offsetTop - 100, left: 0, behavior: 'smooth' });
        }
    };

    return (
        <BigContentHeaderLayout
            pathname={location.pathname}
            title={entry.frontmatter.title}
            description={entry.frontmatter.description}
            content={
                <FeatureItem
                    key={firstItem.title}
                    title={firstItem.title}
                    description={firstItem.description}
                    image={getImage(firstItem.image)}
                    headerItem
                    classes={{
                        root: classes.headerFeatureItemRoot,
                        title: classes.headerFeatureItemTitle,
                        image: classes.headerFeatureItemImage,
                    }}
                    absolutePositionedImage
                    actionButtons={<CTAButtons />}
                />
            }
        >
            <div className={classes.sectionsLinkWrapper}>
                <button onClick={() => scrollToElement(TITLE_ELEMENT_IDS.features)} className={classes.sectionLink} type="button">
                    Features
                </button>
                <button
                    onClick={() => scrollToElement(TITLE_ELEMENT_IDS.publications)}
                    className={classes.sectionLink}
                    type="button"
                >
                    Publications
                </button>
            </div>
            {subDescription1 && subDescription2 && (
                <DescriptionBox>
                    <Grid container spacing={5}>
                        <Grid item md={6}>
                            <Typography variant="body2" dangerouslySetInnerHTML={{ __html: subDescription1 }} />
                        </Grid>
                        <Grid item md={6}>
                            <Typography variant="body2" dangerouslySetInnerHTML={{ __html: subDescription2 }} />
                        </Grid>
                    </Grid>
                </DescriptionBox>
            )}

            {entry.frontmatter.items.map(
                (item, index) =>
                    index > 0 && (
                        <div key={item.title}>
                            {index === 1 && (
                                <h2 id={TITLE_ELEMENT_IDS.features} className={classes.sectionTitle}>
                                    Features
                                </h2>
                            )}
                            <FeatureItem
                                direction={index % 2 === 1 ? 'row-reverse' : 'row'}
                                title={item.title}
                                description={item.description}
                                image={getImage(item.image)}
                            />
                        </div>
                    )
            )}
            <div className={classes.publicationsWrapper}>
                <div className={classes.publicationsHeader}>
                    <h2 id={TITLE_ELEMENT_IDS.publications} className={classes.sectionTitle}>
                        Publications
                    </h2>
                    <Button variant="contained" component={Link} to="/publications" className={classes.publicationsHeaderButton}>
                        All Publications
                    </Button>
                </div>
                <PublicationsAccordionList
                    items={publications.items}
                    loading={publications.initialLoading}
                    error={publications.isError}
                />
            </div>
        </BigContentHeaderLayout>
    );
};

TherapeuticAreaPage.propTypes = {
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
    data: PropTypes.shape({
        site: PropTypes.shape({
            siteMetadata: PropTypes.shape({
                menu: PropTypes.arrayOf(
                    PropTypes.shape({
                        title: PropTypes.string,
                        subMenu: PropTypes.arrayOf(
                            PropTypes.shape({
                                title: PropTypes.string,
                                url: PropTypes.string,
                                id: PropTypes.number,
                            })
                        ),
                    })
                ),
            }),
        }),
        markdownRemark: PropTypes.shape({
            frontmatter: PropTypes.shape({
                title: PropTypes.string,
                description: PropTypes.string,
                subDescription1: PropTypes.string,
                subDescription2: PropTypes.string,
                items: PropTypes.oneOfType([PropTypes.array]),
                path: PropTypes.string,
            }),
            html: PropTypes.string,
        }),
    }).isRequired,
};

export default TherapeuticAreaPage;
